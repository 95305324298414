<template>
  <div>
    <DisplaceDetail ref="displaceDetail" :id="contractId" :allowEdit="true"
      @addCommodity="handleAddCommodity" @editCommodity="handleEditCommodity"/>

    <ModifyCommodity ref="modifyCommodity" @on-success="hanldeModifySuccess"/>
  </div>
</template>

<script>
import DisplaceDetail from '@/components/approvalTask/components/Replacement'
import ModifyCommodity from '../detailComponents/ModifyCommodity'

export default {
  components: {
    DisplaceDetail, ModifyCommodity
  },
  props: {
    contractId: {
      type: Number
    }
  },
  methods: {
    handleAddCommodity () {
      this.$refs.modifyCommodity.showModal(this.contractId)
    },
    handleEditCommodity (updateBean) {
      this.$refs.modifyCommodity.showModal(this.contractId, updateBean)
    },
    hanldeModifySuccess () {
      this.$refs.displaceDetail.loadProducts()
    }
  }
}
</script>
